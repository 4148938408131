<template>
  <div class="uk-section home-floor8">
    <div class="uk-container uk-container-large">
      <div class="home-floor8-title">公司动态</div>
      <div uk-grid class="uk-grid">
        <div class="uk-width-2-5">
          <div class="home-floor8-big">
            <div class="home-floor8-big-date">{{ big.date }}</div>
            <div class="home-floor8-big-title">{{ big.title }}</div>
            <div class="home-floor8-big-content">{{ big.content }}</div>
            <div>
              <img class="home-floor8-big-img" :src="big.img" />
            </div>
            <div class="uk-flex uk-flex-right" style="margin-top: 20px">
              <div>
                <div class="uk-flex home-floor8-big-tag">
                  <img
                    class="home-floor8-big-tag-icon"
                    src="@/assets/26f3121b-4bad-74c1-21ac-60ad86ca2604.png"
                  />
                  <div class="home-floor8-big-tag-label">211</div>
                </div>
              </div>
              <div style="margin-left: 15px">
                <div class="uk-flex home-floor8-big-tag">
                  <img
                    class="home-floor8-big-tag-icon"
                    src="@/assets/862be8f6-b8f3-c1ac-82e4-b22292269bcd.png"
                  />
                  <div class="home-floor8-big-tag-label">421</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-3-5">
          <div
            class="home-floor8-item"
            v-for="(item, index) in list"
            :key="index"
          >
            <div uk-grid class="uk-grid">
              <div>
                <div class="home-floor8-item-date">
                  <div class="home-floor8-item-date-day">{{ item.r }}</div>
                  <div>{{ item.y }}</div>
                  <div>{{ item.n }}</div>
                </div>
              </div>
              <div>
                <div class="home-floor8-item-dot"></div>
              </div>
              <div class="uk-flex-1">
                <div class="home-floor8-item-title">{{ item.title }}</div>
                <div class="home-floor8-item-content">{{ item.content }}</div>
              </div>
              <div>
                <div class="home-floor8-item-arraw">
                  <span uk-icon="arrow-right"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          n: "2023",
          y: "11",
          r: "3",
          date: "2023-11-3",
          title: "吾叙酒旅通小红书saas发布",
          content:
            "这一年公司成立了，几排办公桌，几台电脑：几个还踹梦想与责任的理工技术人默默的从零开始，从最底层，一点一滴的为公司的未来发展，打下结实基础。",
          img: require("@/assets/5bea65fb-e0ac-a961-c92b-33dd4b769eec.png"),
        },
        {
          n: "2019",
          y: "3",
          r: "3",
          date: "2019-3-3",
          title: "酒旅通微信私域系统发布",
          content:
            "提供'SaaS+营销+服务”的一站式数字化营销服务。主要服务西南地区，民宿酒店集团与酒旅通达成深度合作。",
        },
        {
          n: "2017",
          y: "8",
          r: "1",
          date: "2017-8-1",
          title: "公司成立",
          content:
            "这一年公司成立了，几排办公桌，几台电脑：几个还踹梦想与责任的理工技术人默默的从零开始，从最底层，一点一滴的为公司的未来发展，打下结实基础。",
        },
      ],
    };
  },
  computed: {
    big() {
      return this.list[0];
    },
  },
};
</script>

<style lang="less">
.home-floor8 {
  &-title {
    font-size: 40px;
    line-height: 58px;
    color: #333;
    position: relative;
    text-align: center;
    padding-bottom: 24px;
    margin-bottom: 80px;
    &::after {
      position: absolute;
      position: absolute;
      display: block;
      content: "";
      width: 5%;
      height: 7px;
      border-radius: 3.5px;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      background: @linear-gradient;
    }
  }
  &-big {
    border-radius: 24px;
    padding: 50px;
    background: #f5f5f5;
    color: #333333;
    &-date {
      font-size: 21px;
    }
    &-title {
      font-size: 23px;
    }
    &-content {
      font-size: 16px;
      padding: 30px 0;
    }
    &-tag {
      &-icon {
        width: 17px;
        display: block;
        margin-right: 5px;
      }
      &-label {
        font-size: 14px;
        color: #a0a0a0;
      }
      &-tag + &-tag {
        margin-left: 15px;
      }
    }
  }
  &-item {
    cursor: pointer;
    overflow: hidden;
    color: #666666;
    padding: 40px 30px;
    &-date {
      font-size: 16px;
      color: #666666;
      &-day {
        font-size: 53px;
      }
      > div + div {
        margin-top: 8px;
      }
    }
    &-dot {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #999999;
    }
    &-title {
      font-size: 20px;
      margin-bottom: 20px;
    }
    &-content {
      font-size: 16px;
    }
    &-arraw {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      opacity: 0;
      background: @linear-gradient;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #fff;
    }
    &:hover {
      [class*="-date"],
      [class*="-title"] {
        color: @color;
      }
      [class*="-dot"] {
        background: @color;
      }
      [class*="-arraw"] {
        opacity: 1;
      }
    }
  }
  &-item + &-item {
    border-top: 1px solid #70707030;
  }
}
</style>