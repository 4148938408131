<template>
  <div class="uk-section home-floor4">
    <div class="uk-container uk-container-large home-floor4">
      <div class="home-floor4-title">行业解决方案</div>
      <div uk-slider>
        <div class="uk-position-relative">
          <div class="uk-slider-container">
            <ul class="uk-slider-items uk-child-width-1-4 uk-grid">
              <li v-for="(item, index) in list" :key="index">
                <div class="home-floor4-item" @click="showQr">
                  <div class="home-floor4-item-bg"></div>
                  <div class="home-floor4-item-content">
                    <div>
                      <div class="home-floor4-item-imgBox">
                        <img class="home-floor4-item-img" :src="item.icon" />
                        <img class="home-floor4-item-acImg" :src="item.acIcon" />
                      </div>
                      <div class="home-floor4-item-label">{{ item.label }}</div>
                      <div class="home-floor4-item-desc">{{ item.desc }}</div>
                    </div>
                    <div class="home-floor4-item-tags">
                      <div
                        class="home-floor4-item-tag"
                        v-for="(tag, index) in item.tags"
                        :key="index"
                      >
                        <span
                          class="home-floor4-item-tag-icon"
                          uk-icon="icon: check"
                        ></span>
                        <span>{{ tag }}</span>
                      </div>
                    </div>
                    <div class="home-floor4-item-btn">免费申请</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <a
              class="uk-position-center-left-out uk-position-small"
              href="#"
              uk-slidenav-previous
              uk-slider-item="previous"
            />
            <a
              class="uk-position-center-right-out uk-position-small"
              href="#"
              uk-slidenav-next
              uk-slider-item="next"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          acIcon: require("@/assets/e504f868-1ca9-4276-95dd-7e0070cd9bce.png"),
          icon: require("@/assets/62f95376-30c9-4c7c-a545-f4eceb35fdf7.png"),
          label: "微信生态数字化运营",
          desc: "打通您的“公域+私域”流量，构建统一的渠道和会员体系",
          tags: [
            "精细化运营微信生态",
            "打通并统一多平台数据",
            "全触点运营，盘活线下",
          ],
        },
        {
          acIcon: require("@/assets/8843b8e5-5844-4eef-84d6-b01fcfbe7d64.png"),
          icon: require("@/assets/688e1be6-8906-4ac5-824b-68eec8797f70.png"),
          label: "旅行社",
          desc: "建立景区数字化基础矩阵及营销体系，线上线下融合营销",
          tags: [
            "拥有独立的小程序平台",
            "引流、锁客、转化、裂变、复购",
            "全触点运营，盘活线下",
          ],
        },
        {
          acIcon: require("@/assets/53977859-fce2-4ea6-8d2a-18ca75704384.png"),
          icon: require("@/assets/1638a32f-f49a-4772-a1eb-b09863ba386a.png"),
          label: "景区",
          desc: "建立景区数字化基础矩阵及营销体系，线上线下融合营销",
          tags: ["数字化矩阵赋能", "全渠道营销打通", "全民分销体系建立"],
        },
        {
          acIcon: require("@/assets/3ad4cfe1-c4f7-4696-a121-5e498b31879a.png"),
          icon: require("@/assets/5e99460b-8efc-4f8a-8d2b-f0db2731f9ef.png"),
          label: "酒店民宿",
          desc: "酒店数字化转型，酒店商城全渠道获客，构建私域流量",
          tags: ["丰富的引流工具", "众多预订渠道入口", "全员营销体系建立"],
        },
        {
          acIcon: require("@/assets/443b208e-6ef1-458e-879f-46bc5bed5add.png"),
          icon: require("@/assets/0b1031b5-3e8f-4c3d-a244-659002e17e03.png"),
          label: "主题乐园",
          desc: "打通您的“公域+私域”流量，打造属于自己的预订分销平台",
          tags: [
            "帮你打通OTA分销渠道",
            "平台数据驱动酒店运营",
            "搭载数字化智慧无人酒店",
          ],
        },
        {
          acIcon: require("@/assets/8d10d95e-d05d-4fcd-9cf7-664ac62f1a01.png"),
          icon: require("@/assets/2889f72b-18e3-4417-a9db-75a596b3400e.png"),
          label: "自媒体",
          desc: "助力景区一站式统筹管理园内所有游玩项目",
          tags: [
            "高效便捷的一卡通管理模式",
            "智能化的二次消费管理",
            "智能化票务系统",
          ],
        },
        {
          acIcon: require("@/assets/5634339f-facc-4830-bdf3-43324062e28d.png"),
          icon: require("@/assets/333267cd-c1bf-48b6-8553-b95d09c630b7.png"),
          label: "社交电商",
          desc: "为企业打造专属私域流量-数字营销获客利器",
          tags: [
            "快速打造专属社交平台",
            "分销体系增强用户消费粘性",
            "线上营销设置，助力消费增长",
          ],
        },
        {
          acIcon: require("@/assets/5e24f5c9-3495-4d11-a6a0-8bf821034075.png"),
          icon: require("@/assets/4c149fb8-5dc6-4883-99ad-4d083611c86d.png"),
          label: "文旅集团",
          desc: "打造一套可以促进文旅业态、管理升级的营销运营管理系统",
          tags: [
            "全面提升行业管理水平",
            "助力提升旅客体验",
            "促进文旅服务升级",
          ],
        },
        {
          acIcon: require("@/assets/61a5b67b-4691-4c26-a26c-1b266533f2c5.png"),
          icon: require("@/assets/5f24c1bf-a163-4812-81aa-ae51e8c709c0.png"),
          label: "品牌零食",
          desc: "赋能线下门店，帮助门店实现更好的卖货",
          tags: [
            "舒适购物，打造品牌忠诚度",
            "多门店联动，超强消费体验",
            "实现品牌的全数字化经营",
          ],
        },
        {
          acIcon: require("@/assets/e37474b1-d08f-44aa-9510-8d77c46d41cb.png"),
          icon: require("@/assets/bdfacbf4-2b97-4b07-9c33-0fb957f22c82.png"),
          label: "直播电商",
          desc: "帮助企业带来直播红利，同时也给平台增长带来新的契机",
          tags: [
            "挂载小程序，加速购买决策",
            "分销体系 智能锁粉",
            "满足各种场景，卖货变现",
          ],
        },
      ],
    };
  },
};
</script>

<style lang="less">
.home-floor4 {
  .uk-slider-container {
    padding: 15px;
  }
  &-title {
    font-size: 40px;
    line-height: 58px;
    color: #333333;
    position: relative;
    text-align: center;
    padding-bottom: 24px;
    margin-bottom: 80px;
    &::after {
      position: absolute;
      position: absolute;
      display: block;
      content: "";
      width: 10%;
      height: 7px;
      border-radius: 3.5px;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      background: @linear-gradient;
    }
  }
  &-item {
    height: 553px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: 0.5s box-shadow;
    overflow: hidden;
    background-color: #fff;
    border-radius: 18px;
    text-align: center;
    color: #333333;
    cursor: pointer;
    &-bg {
      width: 0;
      margin: auto;
      background: @linear-gradient;
      height: 100%;
      transition: 0.3s width;
    }
    &-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &-imgBox {
      position: relative;
      height: 60px;
      width: 60px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
    }
    &-img,
    &-acImg {
      position: absolute;
      display: block;
      max-height: 100%;
      max-width: 100%;
      margin: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: 0.5s all;
    }
    &-acImg {
      opacity: 0;
    }
    &-label {
      font-size: 24px;
      padding-bottom: 14px;
      position: relative;
      margin-bottom: 14px;
      &::after {
        height: 5px;
        border-radius: 4px;
        content: "";
        display: block;
        width: 74px;
        margin: auto;
        position: absolute;
        background: @linear-gradient;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    &-desc {
      font-size: 18px;
      line-height: 1.8;
    }
    &-tag {
      font-size: 16px;
      text-align: center;
      display: flex;
      align-items: center;
      &-icon {
        color: @color;
        margin-right: 4px;
      }
    }
    &-tag + &-tag {
      margin-top: 10px;
    }
    &-btn {
      width: 158px;
      height: 51px;
      border-radius: 6px;
      background: #fe4352;
      font-size: 20px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      transition: 0.3s all;
    }

    &:hover {
      box-shadow: @box-show;
      color: #fff;
      .home-floor4-item-bg {
        width: 100%;
      }
      .home-floor4-item-img {
        opacity: 0;
      }
      .home-floor4-item-acImg {
        opacity: 1;
      }
      .home-floor4-item-tag-icon {
        color: #fff;
      }
      .home-floor4-item-label {
        &::after {
          background: #fff;
        }
      }
      .home-floor4-item-btn {
        background: #fff;
        color: #fe4352;
      }
    }
  }
}
</style>