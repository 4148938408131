<template>
  <div class="home-floor9">
    <div class="home-floor9-bg">
      <div class="uk-section">
        <div class="uk-container uk-container-large">
          <div class="home-floor9-title">开启旅游系统和营销运营之旅！</div>
          <div class="home-floor9-subtitle">
            立即注册，和吾叙酒旅通系统专家一起探讨数字化！
          </div>
          <div class="home-floor9-btn" @click="showQr">免费申请</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.home-floor9 {
  background-image: @linear-gradient;
  &-bg {
    background-image: url("~@/assets/f1053102-25c1-4f7d-89d2-07b40e9eef81.png");
    background-size: cover;
  }
  &-title {
    font-size: 40px;
    color: #fff;
    text-align: center;
    margin-bottom: 16px;
  }
  &-subtitle {
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin-bottom: 72px;
  }
  &-btn {
    width: 210px;
    padding: 19px 65px;
    border-radius: 15px;
    background: #fff;
    box-shadow: @box-show;
    color: @color;
    font-size: 20px;
    text-align: center;
    margin: auto;
    cursor: pointer;
  }
}
</style>