<template>
  <div class="uk-container uk-container-large home-floor2">
    <div uk-slider>
      <div class="uk-position-relative">
        <div class="uk-slider-container">
          <ul class="uk-slider-items uk-child-width-1-3 uk-grid">
            <li v-for="(item, index) in list" :key="index">
              <div class="home-floor2-item">
                <div class="home-floor2-item-bg"></div>
                <div class="home-floor2-item-imgBox">
                  <img class="home-floor2-item-img" :src="item.icon" />
                  <img class="home-floor2-item-acImg" :src="item.acIcon" />
                </div>
                <div class="home-floor2-item-content">
                  <div class="home-floor2-item-label">{{ item.label }}</div>
                  <div class="home-floor2-item-desc">{{ item.desc }}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <a
            class="uk-position-center-left-out uk-position-small"
            href="#"
            uk-slidenav-previous
            uk-slider-item="previous"
          />
          <a
            class="uk-position-center-right-out uk-position-small"
            href="#"
            uk-slidenav-next
            uk-slider-item="next"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          icon: require("@/assets/ed8f496f-4521-4270-b5fa-66acc1fe5926.png"),
          acIcon: require("@/assets/38655c69-33f9-4118-a024-acc25daadd8e.png"),
          label: "旅游企业营销系统",
          desc: "100+功能，助力旅行社、本地生活电商营销运营",
        },
        {
          icon: require("@/assets/6cedcc68-16b6-414b-9f24-f8dc2ce39443.png"),
          acIcon: require("@/assets/b6ef2437-3c7f-46b0-a91c-b90576271b19.png"),
          label: "营销型智慧景区解决方案",
          desc: "打造营销型景区，沉淀景区数字化资产",
        },
        {
          icon: require("@/assets/d5008ccc-4571-464f-85eb-daf0bdfb074f.png"),
          acIcon: require("@/assets/d6a0d2b2-1e70-4443-b5f1-82d52d9c3bd3.png"),
          label: "文旅产业数字化集群平台",
          desc: "促进文旅业态升级、管理升级、服务升级",
        },
        {
          icon: require("@/assets/e867350e-249a-4131-9833-69c747cb679a.png"),
          acIcon: require("@/assets/2cdd8e52-309d-43bd-8785-320f09c808a5.png"),
          label: "目的地大运营",
          desc: "云仓SaaS建立目的地文旅产品资源供应链",
        },
        {
          icon: require("@/assets/8564db62-a747-4e38-a781-7d67cedd842e.png"),
          acIcon: require("@/assets/56421025-413b-4406-988b-43c3762aca69.png"),
          label: "私有化部署",
          desc: "程序代码、数据库、服务器全独享、无限定制需求",
        },
        {
          icon: require("@/assets/342407a1-84d9-492a-8f29-cdd7622a6391.png"),
          acIcon: require("@/assets/3c5ec161-f13a-4cb4-b912-719ee3de75fc.png"),
          label: "事业合伙人",
          desc: "通过渠道、资源、联盟布局共同打造旅游王国",
        },
      ],
    };
  },
};
</script>

<style lang="less">
.home-floor2 {
  .uk-slider-container {
    padding: 15px;
  }
  &-item {
    height: 145px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    position: relative;
    transition: 0.5s box-shadow;
    overflow: hidden;

    cursor: pointer;
    &-bg {
      width: 0;
      background: @linear-gradient;
      height: 100%;
      transition: 0.3s width;
    }
    &-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-imgBox {
      position: absolute;
      bottom: 14px;
      right: 14px;
      height: 77px;
      width: 79px;
    }
    &-img,
    &-acImg {
      position: absolute;
      display: block;
      max-height: 100%;
      max-width: 100%;
      margin: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: 0.5s all;
    }
    &-acImg {
      opacity: 0;
    }
    &-label {
      font-size: 28px;
      line-height: 35px;
      color: #333333;
      transition: 0.5s color;
    }
    &-desc {
      font-size: 18px;
      line-height: 26px;
      color: #666666;
      transition: 0.5s color;
    }
    &:hover {
      box-shadow: @box-show;
      .home-floor2-item-bg {
        width: 100%;
      }
      .home-floor2-item-label,
      .home-floor2-item-desc {
        color: #fff;
      }
      .home-floor2-item-img {
        opacity: 0;
      }
      .home-floor2-item-acImg {
        opacity: 1;
      }
    }
  }
}
</style>