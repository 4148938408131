<template>
  <div class="uk-section">
    <div class="uk-container uk-container-large home-floor7">
      <div uk-grid class="uk-grid">
        <div class="uk-width-2-5">
          <img
            class="home-floor7-img"
            src="@/assets/e73af491-c87d-4f70-82c6-a3dac260a93b.png"
          />
        </div>
        <div class="uk-width-3-5">
          <div class="home-floor7-body">
            <div class="home-floor7-title">关于我们</div>
            <div class="home-floor7-subtitle">About Us</div>
            <div class="home-floor7-content">
              公司成立于2016年7月，曾服务过国内知名旅游酒店集团和saas软件公司。例如订单来了、行李旅宿。公司坐落于成都高新技术开发区天府新谷。目前主营业务为旅行社、景区、营地、酒店、度假民宿等提供saas软件和运营服务。主要专注于文旅垂直行业，通过saas+解决方案为文旅企业快速解决在各种场景下的营销需求。多渠道营销链接、达人分销带货、裂变营销策划、直播代运营等「运营全链路解决方案」。
            </div>
            <div>
              <div class="home-floor7-btn">更多</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.home-floor7 {
  padding: 24px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 6px 36px 0px rgba(0, 0, 0, 0.1);
  &-img {
    display: block;
    width: 100%;
  }
  &-body {
    padding: 20px 0;
  }
  &-title {
    font-size: 32px;
    line-height: 1;
    color: #333333;
    position: relative;
    padding-top: 24px;
    margin-bottom: 14px;
    &::after {
      position: absolute;
      position: absolute;
      display: block;
      content: "";
      width: 5%;
      height: 4px;
      border-radius: 3.5px;
      margin: auto;
      left: 0;
      top: 0;
      background: #fe4352;
    }
  }

  &-subtitle {
    font-size: 16px;
    color: #666666;
  }

  &-content {
    padding: 20px 0;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
  }

  &-btn {
    width: 112px;
    height: 36px;
    background-image: @linear-gradient;
    box-shadow: @box-show;
    padding: 11px 42px;
    font-size: 14px;
    line-height: 1;
    border-radius: 50px;
    color: #ffffff;
    float: right;
    cursor: pointer;
  }
}
</style>