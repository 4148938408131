<template>
  <span uk-scrollspy="repeat:true" @inview="onInview">{{ numStr }}</span>
</template>

<script>
export default {
  props: {
    vlaue: {
      type: Number,
      default: 0,
    },
    time: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      num: this.vlaue,
    };
  },
  computed:{
    numStr(){
        if(this.num >= this.vlaue){
            return this.vlaue;
        }
        return Number(this.num).toFixed(0)
    }
  },
  methods: {
    onInview() {
      this.num = 0;
      console.log("onInview");
      this.inview();
    },
    inview() {
      let step = this.vlaue / this.time;
      setTimeout(() => {
        if (this.num >= this.vlaue) {
          this.num = this.vlaue;
          return;
        }
        this.num = this.num + step;
        this.inview();
      }, 1);
    },
  },
};
</script>

<style>
</style>