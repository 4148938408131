<template>
  <div class="uk-section home-floor6">
    <div class="uk-container uk-container-large">
      <div class="home-floor6-title">大数据和营销科技行业领导者</div>
      <div uk-grid class="uk-child-width-1-4 uk-grid">
        <div v-for="(item, index) in list" :key="index">
          <div class="home-floor6-item">
            <div class="home-floor6-item-value">
              <numscrollspy :vlaue="item.value" />
              <span>{{ item.unti }}</span>
            </div>
            <div class="home-floor6-item-label">{{ item.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numscrollspy from "../../../components/numscrollspy.vue";
export default {
  components: {
    numscrollspy,
  },
  data() {
    return {
      list: [
        {
          value: 1000,
          unti: "+",
          label: "累计服务客户",
        },
        {
          value: 500,
          unti: "+",
          label: "文旅企业合作品牌",
        },
        {
          value: 1000,
          unti: "+",
          label: "新媒体带货达人",
        },
        {
          value: 99.85,
          unti: "%",
          label: "技术稳定性",
        },
      ],
    };
  },
};
</script>

<style lang="less">
.home-floor6 {
  background-image: @linear-gradient;

  &-title {
    font-size: 40px;
    line-height: 58px;
    color: #fff;
    position: relative;
    text-align: center;
    padding-bottom: 24px;
    margin-bottom: 80px;
    &::after {
      position: absolute;
      position: absolute;
      display: block;
      content: "";
      width: 10%;
      height: 7px;
      border-radius: 3.5px;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
    }
  }
  &-item {
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    height: 197px;
    border: 1px solid #f9b8b1;
    border-radius: 10px;
    padding: 50px 15px;
    color: #fff;
    line-height: 1;
    text-transform: uppercase;
    &-label {
      font-size: 27px;
    }

    &-value {
      font-size: 58px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
}
</style>