<template>
  <div class="uk-section home-floor3">
    <div class="uk-container uk-container-large">
      <div class="home-floor3-title">三云一平台，赋能企业数字化营销闭环</div>
      <div class="home-floor3-desc">
        吾叙酒旅通助理旅行社、本地生活电商、景区、酒店等建立专属的系统，结合全渠道精准运营的行动与反馈，实现数字化营销
      </div>
      <div class="uk-section">
        <div uk-grid class="uk-child-width-1-4">
          <div v-for="(item, index) in list" :key="index">
            <div class="home-floor3-item uk-flex uk-flex-column">
              <div class="home-floor3-item-title">{{ item.title }}</div>
              <div class="home-floor3-item-subTitle">{{ item.subTitle }}</div>
              <div class="uk-flex-1 uk-flex uk-flex-center uk-flex-middle">
                <div class="home-floor3-item-imgBox">
                  <img class="home-floor3-item-img" :src="item.icon" />
                  <img class="home-floor3-item-acImg" :src="item.acIcon" />
                </div>
              </div>
              <div class="home-floor3-item-desc" v-html="item.desc"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="uk-flex uk-flex-center uk-child-width-1-4 uk-grid">
          <div>
            <div @click="showQr" class="home-floor3-ljgd uk-flex uk-flex-middle uk-flex-center">
              了解更多
            </div>
          </div>
          <div>
            <div @click="showQr" class="home-floor3-mfsq uk-flex uk-flex-middle uk-flex-center">
              免费申请
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "吾叙营销云",
          subTitle: "We describe marketing cloud",
          icon: require("@/assets/40e685ca-d55e-4787-a01e-94e0188a89cf.png"),
          acIcon: require("@/assets/a34bd2ce-de26-45e2-a672-3d7ffda8b4d0.png"),
          desc: "有效的整合产品资源，实现快速裂变获客<br />建立分销渠道，构建自己的私域流量<br />大数据管理以及资金实时结算",
        },
        {
          title: "吾叙票务云",
          subTitle: "I Syria ticket cloud",
          icon: require("@/assets/8596fcf0-a4c4-4bd0-b772-4faf9012c834.png"),
          acIcon: require("@/assets/3377105a-1fc9-4bc1-aee6-82450d2de711.png"),
          desc: "助力景区实现智慧旅游<br />构建万物互联的新旅游生态<br />多种方式灵活核验",
        },
        {
          title: "吾叙管理云",
          subTitle: "I manage the cloud",
          icon: require("@/assets/d00a92f4-e433-4ed2-a9c2-133b477cb097.png"),
          acIcon: require("@/assets/3d103b36-9b86-45d2-ab9c-7896d9385075.png"),
          desc: "再小的旅行社也需要企业内部管理数字化<br />产品互通、订单互通、财务上通下达<br />大数据分析",
        },
        {
          title: "吾叙云仓平台",
          subTitle: "I say cloud warehouse platform",
          icon: require("@/assets/cd9f4062-744b-45be-b2e8-629efcd95cc2.png"),
          acIcon: require("@/assets/b2e92581-aa1a-4bfa-8562-0a895c6e4407.png"),
          desc: "价格透明化，轻松获取底价商品<br />产品自动收录到云仓平台<br />一键分发全国各地，进行分销",
        },
      ],
    };
  },
};
</script>

<style lang="less">
.home-floor3 {
  &-title {
    font-size: 40px;
    line-height: 58px;
    color: #333333;
    position: relative;
    text-align: center;
    padding-bottom: 24px;
    margin-bottom: 24px;
    &::after {
      position: absolute;
      width: 10%;
      position: absolute;
      display: block;
      content: "";
      width: 190px;
      height: 7px;
      border-radius: 3.5px;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      background: @linear-gradient;
    }
  }
  &-desc {
    font-size: 16px;
    color: #333333;
    text-align: center;
  }
  &-item {
    padding: 40px 15px;
    background-color: #fff;
    border-radius: 18px;
    text-align: center;
    height: 446px;
    cursor: pointer;
    transition: 0.5s all;

    &-title {
      font-size: 24px;
      line-height: 24px;
      color: #333333;
      margin-bottom: 16px;
    }
    &-subTitle {
      font-size: 11px;
      line-height: 11px;
      color: #666666;
    }
    &-desc {
      font-size: 16px;
      color: #333333;
      line-height: 1.8;
      height: 78px;
    }
    &-imgBox {
      height: 123px;
      width: 123px;
      position: relative;
    }
    &-img,
    &-acImg {
      position: absolute;
      display: block;
      max-height: 100%;
      max-width: 100%;
      margin: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: 0.5s all;
    }
    &-acImg {
      opacity: 0;
    }
    &:hover {
      scale: 1.01;
      box-shadow: 0px 0px 15px 0px rgba(254, 97, 71, 0.3);
      .home-floor3-item-img {
        opacity: 0;
      }
      .home-floor3-item-acImg {
        opacity: 1;
      }
    }
  }
  &-ljgd {
    height: 81px;
    border-radius: 12px;
    border: 1px solid #fe4352;
    background: #ffd2d6;
    font-size: 32px;
    color: #ff0e24;
    cursor: pointer;
  }
  &-mfsq {
    height: 81px;
    border-radius: 12px;
    border: 1px solid #fe4352;
    background: #fe4352;
    font-size: 32px;
    color: #fff;
    cursor: pointer;
  }
}
</style>