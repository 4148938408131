<template>
  <div uk-slideshow="ratio:1920:800;autoplay: true;finite: true">
    <ul class="uk-slideshow-items">
      <li @click="showQr">
        <img uk-cover src="@/assets/9f3c502c-db7b-4bee-a3fd-27842a4a4cbd.png" />
      </li>
      <li @click="showQr">
        <img uk-cover src="@/assets/71b54edf-f808-4801-ad13-ec5a61381f35.png" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>