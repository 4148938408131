<template>
  <div class="uk-section home-floor5">
    <div class="uk-container uk-container-large home-floor5">
      <div class="home-floor5-title">数字化赋能助力营销</div>
      <div uk-grid class="uk-child-width-1-3 uk-grid">
        <div v-for="(item, index) in list" :key="index">
          <div class="home-floor5-itemblock">
            <div class="home-floor5-item" @click="showQr">
              <div class="home-floor5-item-content">
                <div class="home-floor5-item-imgBox">
                  <img class="home-floor5-item-img" :src="item.icon" />
                </div>
                <div class="home-floor5-item-label">{{ item.label }}</div>
                <div class="home-floor5-item-desc">{{ item.desc }}</div>
              </div>
              <div class="home-floor5-item-btn">免费申请</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          icon: require("@/assets/d115dd99-da2d-49b8-a24a-f83feeebe438.png"),
          label: "打造商城",
          desc: "多元化商城、购物车、多店铺、店铺装修、海报模版、自定义海报、组合下单、常用联系人",
        },
        {
          icon: require("@/assets/3ffd604f-b30a-460c-8b3c-ff00cb59b68c.png"),
          label: "资源共享",
          desc: "微程云仓、OTA串货、串货授信、导码管理等",
        },
        {
          icon: require("@/assets/076db637-f5c4-4488-8a8f-02cff2e2936a.png"),
          label: "分销裂变",
          desc: "无限两级分销、余额消费、分销商升级机制、自动发展分销商、分销商等级自动升级等",
        },
        {
          icon: require("@/assets/fadd184f-a417-49a1-9fbf-7b5fc6aa48bc.png"),
          label: "引流获客",
          desc: "免费直播、抖音引流、社群引流、关联公众号、分享朋友圈、电影票系统等",
        },
        {
          icon: require("@/assets/ab176cb3-d89d-4df3-bbb3-f2b6447f509b.png"),
          label: "营销玩法",
          desc: "红包裂变、优惠券、活动专题、营销短信、0元购、兑换码、积分体系、线路推广、开抢通知等",
        },
        {
          icon: require("@/assets/025c9246-4698-40c6-9cdd-b0a328c6a548.png"),
          label: "多种核销",
          desc: "闸机核销、手持验票机核销、小程序核销、 一码多验、一单一码等",
        },
        {
          icon: require("@/assets/399daf63-928b-4d70-a098-b9edf8a4e663.png"),
          label: "运营管理",
          desc: "商家公众号通知、消息通知、实时结算、 可视化管理、数据统计等",
        },
        {
          icon: require("@/assets/0726228a-58f4-4791-bdf5-a9ccf9edfe54.png"),
          label: "票务系统",
          desc: "自助售票系统、核验票务系统、人脸识别系统、 识客计数系统等",
        },
        {
          icon: require("@/assets/5f7e7dd0-a52f-483c-abd9-bfd95df55c01.png"),
          label: "硬件设备",
          desc: "闸机、窗口售票机、手持设备、人脸识别设备、 打印机、身份证阅读器、智能柜等",
        },
      ],
    };
  },
};
</script>

<style lang="less">
.home-floor5 {
  &-title {
    font-size: 40px;
    line-height: 58px;
    color: #333333;
    position: relative;
    text-align: center;
    padding-bottom: 24px;
    margin-bottom: 80px;
    &::after {
      position: absolute;
      position: absolute;
      display: block;
      content: "";
      width: 10%;
      height: 7px;
      border-radius: 3.5px;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      background: @linear-gradient;
    }
  }
  &-itemblock {
    padding: 30px 22px;
    height: 247px;
    transition: 0.5s box-shadow;
    background-color: #fff;
    border-radius: 18px;
    color: #333333;
    &:hover {
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
      [class*="-item-content"] {
        margin-top: -88px;
      }
    }
  }
  &-item {
    overflow: hidden;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    overflow: hidden;
    height: 100%;
    &-content {
      height: 100%;
      transition: 0.3s margin-top;
    }
    &-imgBox {
      height: 64px;
      width: 64px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
    }
    &-img,
    &-acImg {
      max-height: 100%;
      max-width: 100%;
      display: block;
      transition: 0.5s all;
    }
    &-acImg {
      opacity: 0;
    }
    &-label {
      font-size: 24px;
      position: relative;
      margin-bottom: 12px;
      color: #333333;
    }
    &-desc {
      font-size: 16px;
      line-height: 25px;
      color: #424242;
      height: 50px;
      overflow: hidden;
    }

    &-btn {
      margin-top: 34px;
      width: 177px;
      height: 54px;
      border-radius: 12px;
      background: @linear-gradient;
      font-size: 20px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>