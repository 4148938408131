<template>
  <div>
    <!-- 楼层一 -->
    <floor-1 />
    <!-- 楼层二 -->
    <floor-2 />
    <!-- 楼层三 -->
    <floor-3 />
    <!-- 楼层四 -->
    <floor-4 />
    <!-- 楼层五 -->
    <floor-5 />
    <!-- 楼层六 -->
    <floor-6 style="margin-bottom:-250px;padding-bottom:250px" />
    <!-- 楼层七 -->
    <floor-7 />
    <!-- 楼层八 -->
    <floor-8 />
    <!-- 楼层九 -->
    <floor-9 />
  </div>
</template>

<script>
import floor1 from "./components/floor1.vue";
import floor2 from "./components/floor2.vue";
import floor3 from "./components/floor3.vue";
import floor4 from "./components/floor4.vue";
import floor5 from "./components/floor5.vue";
import floor6 from "./components/floor6.vue";
import floor7 from "./components/floor7.vue";
import floor8 from "./components/floor8.vue";
import floor9 from "./components/floor9.vue";
export default {
  components: {
    floor1,
    floor2,
    floor3,
    floor4,
    floor5,
    floor6,
    floor7,
    floor8,
    floor9
  },
};
</script>

<style>
</style>